import axios from "axios"
import { API_URL } from "./ApiURL"

export const UserLogin = async (body) => {
  try {
    const { data } = await axios.post(`${API_URL}login/loginpasscode`, body)
    return data
  } catch (error) {
    console.log(error)
  }
}
