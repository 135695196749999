import React, { useEffect } from "react"
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  IconButton,
  TextField,
} from "@mui/material"
import "primeflex/primeflex.css"
import AlarmIcon from "@mui/icons-material/Alarm"
import CheckRoundedIcon from "@mui/icons-material/CheckRounded"
import ClearRoundedIcon from "@mui/icons-material/ClearRounded"
import LoginIcon from "@mui/icons-material/Login"
import Alert from "react-bootstrap/Alert"
import { useState } from "react"
import { Navigate } from "react-router-dom"
import { detectIncognito } from "detectincognitojs"
import { UserLogin } from "../services/apiUserServices"
import { useNavigate } from "react-router-dom"
import { KeyCode } from "../services/MainServices"
import Loading from "../img/spinner.gif"
import Swal from "sweetalert2"

import "../css/Passcode.css"
const Passcode = () => {
  let usecookies = localStorage.getItem("usercode")
  const navigate = useNavigate()
  const [value, setValue] = useState("")
  const [show, setShow] = useState(true)
  const [alert, setAlert] = useState(false)
  const [user, setUser] = useState(null)

  const onClick = (number) => {
    var newval = value + number
    if (newval.length <= 5) {
      setValue(newval)
    }
  }

  const Login = async () => {
    Swal.fire({
      title: "Please wait!",
      text: "Connecting to the server...",
      imageUrl: Loading,
      imageWidth: 200,
      imageHeight: 200,
      allowOutsideClick: false,
      showConfirmButton: false,
    })
    let data = await UserLogin({ password: value })
    console.log(data)
    if (data.flag == 1) {
      localStorage.clear()
      localStorage.setItem("usercode", data.usercode.toUpperCase())
      localStorage.setItem("agentname", data.name.toUpperCase())
      localStorage.setItem("accesstoken", data.accessToken)
      localStorage.setItem("version", data.version)
      // localStorage.setItem("qrposition", data.qrposition)
      localStorage.setItem("enable_betting", data.enable_betting)
      let keydata = await KeyCode()
      // if (localStorage.getItem("KeyCode") == null) {
      // localStorage.setItem("keycode", keydata[0].code)
      //  }
      console.log(keydata)

      setUser(localStorage.getItem("usercode"))
      Swal.close()
      // navigate("/main")
    } else {
      Swal.close()
      setAlert(true)
    }
  }
  const Clear = () => {
    setValue(value.slice(0, -1))
  }

  useEffect(() => {
    // localStorage.clear()
  }, [value])
  return (
    <>
      {usecookies != null ? (
        <Navigate to="/main" replace={true} />
      ) : (
        <Navigate to="/" replace={true} />
      )}
      <Box
        sx={{
          // display: "flex",
          p: "30px",
          width: "100%",
          position: "absolute",
          justifyContent: "center",
          top: 50,
        }}
      >
        {alert ? (
          <Alert variant="danger" onClose={() => setAlert(false)} dismissible>
            <Alert.Heading>Please Try Again!</Alert.Heading>
          </Alert>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "center",
          top: 150,
        }}
      >
        <div className="p-grid">
          <div
            className="p-col"
            style={{ marginBottom: "20px", marginLeft: "10px" }}
          >
            {/* <TextField
              variant="outlined"
              InputProps={{
                sx: {
                  "& input": {
                    textAlign: "center",
                  },
                  fontWeight: "bold",
                },
              }}
              autoFocus
              value={value}
              margin="dense"
            /> */}
            <TextField
              size="large"
              variant="outlined"
              InputLabelProps={{
                style: {
                  fontSize: 25,
                  backgroundColor: "#FFF",
                  paddingLeft: 4,
                  paddingRight: 4,
                  color: "#383838",
                },
              }}
              inputProps={{
                style: {
                  textAlign: "center",
                  fontSize: 25,
                  height: 50,
                  width: 210,
                  padding: "0 14px",
                  fontWeight: "bold",
                },
              }}
              value={value}
              autoFocus
            />
          </div>
          <div className="p-col" style={{ marginLeft: "10px" }}>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("1")}
            >
              <b>1</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("2")}
            >
              <b>2</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("3")}
            >
              <b>3</b>
            </IconButton>
          </div>
          <div
            className="p-col"
            style={{ marginTop: "20px", marginLeft: "10px" }}
          >
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("4")}
            >
              <b>4</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("5")}
            >
              <b>5</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("6")}
            >
              <b>6</b>
            </IconButton>
          </div>
          <div
            className="p-col"
            style={{ marginTop: "20px", marginLeft: "10px" }}
          >
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("7")}
            >
              <b>7</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("8")}
            >
              <b>8</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("9")}
            >
              <b>9</b>
            </IconButton>
          </div>
          <div
            className="p-col"
            style={{ marginTop: "20px", marginLeft: "10px" }}
          >
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => Clear()}
            >
              {/* <ClearRoundedIcon
                sx={{
                  mt: 1,
                }}
              /> */}
              <b>X</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => onClick("0")}
            >
              <b>0</b>
            </IconButton>
            <IconButton
              size="normall"
              color="secondary"
              sx={{
                borderRadius: 10,
                width: "50px",
                textAlign: "center",
                backgroundColor: "#b1ddfa",
                mx: 2,
              }}
              onClick={() => Login()}
            >
              <b> &#x2713;</b>
            </IconButton>
          </div>
        </div>
      </Box>
    </>
  )
}

export default Passcode
