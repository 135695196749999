import axios from "axios"
import api from "./interceptorServices"
import { API_URL } from "./ApiURL"

const GetBetsPerAgent = async (body) => {
  try {
    const { data } = await api.post(`${API_URL}bet/betperagent`, body)
    // console.log(data)
    return data
  } catch (error) {
    console.log(error)
  }
}

const GetBets = async (body) => {
  try {
    const { data } = await api.post(`${API_URL}bet/bettranscation`, body)
    // console.log(data)
    return data
  } catch (error) {
    console.log(error)
  }
}

const GetWinningPerAgent = async (body) => {
  try {
    const { data } = await api.post(
      `${API_URL}report/getwinningbetsperteller`,
      body
    )
    // console.log(data)
    return data
  } catch (error) {
    console.log(error)
  }
}

export { GetBetsPerAgent, GetBets, GetWinningPerAgent }
